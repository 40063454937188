import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "create-instrctor" }
const _hoisted_2 = { class: "create-instrctor__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "create-instrctor__button" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Field = _resolveComponent("Field")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/super-admin/school-admin-list",
      class: "create-instrctor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Create school admin "))
        : _createCommentVNode("", true),
      (this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Edit school admin "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      onSubmit: _ctx.createSchoolAdmin,
      class: "create-instrctor__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("label", {
            for: "name",
            class: "form-group-label"
          }, "Name ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "full_name",
            modelValue: this.full_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.full_name) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.full_name
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "email",
            class: "form-group-label"
          }, "Email ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "email",
            modelValue: this.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.email) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.email
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "password",
            class: "form-group-label"
          }, "Password ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "password",
            name: "password",
            modelValue: this.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.password) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.password
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "password_confirmation",
            class: "form-group-label"
          }, "Repeat password ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "password",
            name: "password_confirmation",
            modelValue: this.password_confirmation,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.password_confirmation) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.password_confirmation
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "choose_school_admin",
            class: "form-group-label"
          }, "Choose school ", -1)),
          _createVNode(_component_Select, {
            data: _ctx.dataSchools,
            "select-name": this.schoolPlacholder,
            class: "category__select",
            title: 'name',
            onOptionSelected: _ctx.handleOptionSelected,
            clearActive: false
          }, null, 8, ["data", "select-name", "onOptionSelected"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", _hoisted_15, [
            (!this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Create"))
              : _createCommentVNode("", true),
            (this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Save"))
              : _createCommentVNode("", true)
          ]),
          (this.serverError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_AlertBlock, {
                  message: this.serverError
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}