
import { defineComponent } from 'vue'
import ArrowBack from '@/assets/svg/arrow-back.svg?inline'
import { Form, Field } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import authHeader from '@/services/auth-header'
import Select from '@/components/atoms/CustomSelect.vue'

export default defineComponent({
  name: 'CreateSchoolAdmin',
  data () {
    return {
      imagePreview: null,
      isPreview: false,
      full_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      errorInstructor: null,
      schoolsSchools: null,
      file: null,
      adminId: null,
      adminData: {},
      editTeg: false,
      serverError: null,
      dataSchools: [],
      schoolIds: [],
      schoolPlacholder: 'Choose school'
    }
  },
  components: {
    ArrowBack,
    Form,
    Field,
    AlertBlock,
    Select
  },
  mounted () {
    this.adminId = this.$route.query.admin
    if (this.$route.query.edit !== undefined) {
      this.editTeg = this.$route.query.edit
    }
    if (this.adminId !== undefined) {
      this.getAdmin(this.adminId)
    }
    this.getSchoolList()
  },
  methods: {
    getAdmin (id) {
      this.$store.dispatch('admins/getAdmin', id).then(
        (res) => {
          this.adminData = res.data
          this.full_name = this.adminData.full_name
          this.email = this.adminData.email
          this.password = this.adminData.password
          this.password_confirmation = this.adminData.password_confirmation
          this.schoolPlacholder = this.adminData.schools[0].title
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    getSchoolList (params) {
      params = ''
      this.$store.dispatch('admins/getSchoolList', params).then(
        (res) => {
          this.dataSchools = res.data
        },
        (error) => {
          this.errorAdmins = error.response.data.errors
        }
      )
    },
    createSchoolAdmin () {
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      formData.append('full_name', this.full_name)
      formData.append('email', this.email)
      if (this.password !== undefined) {
        formData.append('password', this.password)
      }
      if (this.password_confirmation !== undefined) {
        formData.append('password_confirmation', this.password_confirmation)
      }
      this.schoolIds.forEach(id => {
        formData.append('school_ids[]', id)
      })
      if (this.editTeg === false) {
        this.axios.post(API_URL + 'super-admin/admins', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/super-admin/school-admin-list')
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
          this.serverError = error.response.data
        })
      } else {
        this.axios.post(API_URL + 'super-admin/admins/' + this.adminId + '/update', formData, { headers: authHeader() }).then(() => {
          this.$router.push('/super-admin/school-admin-list')
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        })
      }
    },
    handleOptionSelected (selectedOption) {
      this.schoolIds.push(selectedOption.id)
    }
  }
})
